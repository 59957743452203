"use client";

import React from "react";
import Image from "next/image";
import { FaLocationDot } from "react-icons/fa6";

// layouts
import LoliveSearchBar from "@/app/[lang]/(landing)/lolive/containers/LoliveSearchBar";

// components
import Button from "@/shared/Button";
import Tag from "@/shared/Tag";
import Link from "@/components/Link";

// interfaces
import { ICountryResp } from "@/types/api/CountryStateCity";

// assets
import styles from "@/assets/styles/pages/westfield-home-page.module.scss";
import BannerImage from "@/assets/images/landing/WERI-lolive-background.webp";


const getCountryListToRender = (items: { value: string, label: string }[]) => {
  const arraysCount = Math.ceil(items.length / 5);
  const itemsToReturn = [];

  for (let index = 0; index < arraysCount; index++) {
    itemsToReturn.push(items.slice(index * 5, (index + 1) * 5));
  }

  return itemsToReturn;
};

interface IWestfieldHomePageComponentProps {
  countries: ICountryResp[];
}

function WestfieldHomePageComponent({ countries }: IWestfieldHomePageComponentProps) {
  const countriesToUse = (countries || []).slice(-1);

  const countriesOptions = [
    // requested by the client hardcoded to prod id's
    {
      label: "England",
      value: "204__38__207",
    },
    ...countriesToUse.map(({ regions }) => regions.map(({ cities, name }) => ({
      label: name,
      value: cities.map(item => item.id).join("__"),
    }))).flat(),
  ].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className={styles["locl-westfield-home-page"]}>
      <Image
        src={BannerImage}
        fill
        sizes="100vw"
        alt="Westfield Rise banner image"
      />
      <div className={styles["locl-westfield-home-page__main"]}>
        <h1 className={styles["locl-westfield-home-page__title"]}>Your Vision,<br/>Our Stage.</h1>
        <h2 className={styles["locl-westfield-home-page__subtitle"]}>The perfect activation space is just a few clicks away</h2>
        <div className={styles["locl-westfield-home-page__quick-actions"]}>
          <Button
            danger
            className={styles["locl-westfield-home-page__all-locations"]}
            size="large"
            to="/search-results"
          >
          All locations
          </Button>
          <div className={styles["locl-westfield-home-page__quick-actions__list"]}>
            {
              getCountryListToRender(countriesOptions).map((countryList, index) => (
                <div key={index} className={styles["locl-westfield-home-page__quick-actions__nested-list"]}>
                  {
                    countryList.map(({ label, value }) => (
                      <Link className={styles["locl-westfield-home-page__quick-actions__list-link"]} key={value} href={`/search-results?city__in=${value}`}>
                        <Tag
                          icon={<FaLocationDot/>}
                          className={styles["locl-westfield-home-page__tag"]}
                        >
                          { label }
                        </Tag>
                      </Link>
                    ))
                  }
                </div>
              ))
            }
          </div>
          <p className={styles["locl-westfield-home-page__quick-actions__hint"]}>
          Or search by location and date
          </p>
        </div>
        <LoliveSearchBar/>
      </div>
    </div>
  );
}

export default WestfieldHomePageComponent;
